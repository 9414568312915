import { mapGetters } from 'vuex';
const PAGINATION_LIMIT = 200;

export default {
	data () {
		return {
			editAddressModal: {
				active: false,
				address: null
			},
			newAddress: {
				first_name: '',
				last_name: '',
				organisation: '',
				email: ''
			},
			searchKeyword: '',
			currentPage: 1,
			errorMessages: []
		}
	},
	watch: {
		searchKeyword(keyword) {
			if(this.currentPage == 1) {
				this.fetchAddresses();
			} else {
				this.currentPage = 1;
			}
		},
		currentPage(page) {
			this.fetchAddresses();
		}
	},
	computed: {
		pages () {
			return Math.ceil(this.pagination.total / PAGINATION_LIMIT);
		},
		...mapGetters({
			addresses: ['address/addresses'],
			pagination: ['address/pagination'],
			isLoadingResources: 'isLoadingResources'
		})
	},
	created () {
		this.fetchAddresses();
	},
	methods: {
		fetchAddresses () {
			this.$store.dispatch('address/fetchAddresses', {page: this.currentPage, q: this.searchKeyword, limit: PAGINATION_LIMIT})
		},
		createAddress () {
			this.$store.dispatch('address/createAddress', this.newAddress).then(() => {
				this.errorMessages = []
				this.newAddress = {
					first_name: '',
					last_name: '',
					organisation: '',
					email: ''
				}
				
				this.$notify({
					title: 'Adres toegevoegd',
					message: 'Adres is succesvol aangemaakt!',
					type: 'success',
					position: 'bottom-right'
				})
			}).catch((errors) => {
				this.errorMessages = errors;
			});
		},
		deleteAddress (id) {
			this.$store.dispatch('address/deleteAddress', id).then(() => {
				this.$notify({
					title: 'Adres verwijderd',
					message: 'Adres is succesvol verwijderd!',
					type: 'success',
					position: 'bottom-right'
				})
			});
		},
		editAddress (address) {
			this.editAddressModal = {
				active: true,
				address: {...address}
			}
		},
		updateAddress () {
			this.$store.dispatch('address/updateAddress', this.editAddressModal.address).then(() => {
				this.editAddressModal = {
					active: false,
					address: null
				}
				this.$notify({
					title: 'Adres bewerkt',
					message: 'Adres is succesvol bewerkt!',
					type: 'success',
					position: 'bottom-right'
				})
			})
		},
		downloadAddresses () {
			this.$store.dispatch('address/downloadAddresses').then(response => {
				let blob = new Blob([response.body]);
				let link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.setAttribute("download", "export.txt");
				link.click();
				URL.revokeObjectURL(link.href);
			})
		}
	}
}